/* Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  padding: 20px; /* Add padding for top and bottom */
  z-index: 1000; /* Ensure it appears above all other elements */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

/* Modal Content */
.popup-content {
  background-color: #ffffff; /* White background for the modal */
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2); /* Add depth with shadow */
  position: relative; /* Position for the close button */
  animation: fadeIn 0.3s ease; /* Smooth fade-in animation */
  max-height: 80vh; /* Prevent modal from exceeding viewport height */
  overflow-y: auto; /* Scrollable content if it exceeds the height */
}

/* Close Button */
.close-button {
  position: absolute;
  top: 15px; /* Add enough spacing for easy clicking */
  right: 15px;
  background: none;
  border: none;
  font-size: 1.8rem;
  color: #333;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
  z-index: 10; /* Ensure it stays on top of the modal content */
}

.close-button:hover {
  transform: scale(1.2);
  color: #ff5a5a; /* Highlight red on hover */
}

/* Form Heading */
.popup-content h2 {
  font-size: 1.8rem;
  color: #0046d5;
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Form Styling */
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

form input,
form select,
form textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

form input:focus,
form select:focus,
form textarea:focus {
  border-color: #0046d5;
  box-shadow: 0 0 6px rgba(0, 70, 213, 0.3); /* Subtle blue glow */
  outline: none;
}

form textarea {
  resize: vertical; /* Allow vertical resizing only */
  min-height: 120px;
}

/* Submit Button */
form .button-primary {
  width: 100%;
  padding: 0.8rem;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  background: linear-gradient(45deg, #0046d5, #1d82ff);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

form .button-primary:hover {
  background: linear-gradient(45deg, #003bb3, #1a6dcc);
  transform: scale(1.05);
}

/* Error Message */
.error-message {
  color: #ff5a5a;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 1rem;
}

/* Success Message */
.success-message {
  color: #28a745;
  font-size: 0.9rem;
  text-align: center;
  margin-bottom: 1rem;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .popup-content {
    padding: 1.5rem;
  }

  form input,
  form select,
  form textarea {
    font-size: 0.9rem;
    padding: 0.7rem;
  }

  form .button-primary {
    font-size: 1rem;
  }
}





/* Modal Styles (for pop-up) */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  padding: 20px; /* Add padding for top and bottom */
  z-index: 1000; /* Ensure it appears above all other elements */
  overflow-y: auto; /* Allow scrolling if content overflows */
}

.popup-content {
  background-color: #ffffff; /* White background for the modal */
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2); /* Add depth with shadow */
  position: relative; /* Position for the close button */
  animation: fadeIn 0.3s ease; /* Smooth fade-in animation */
  max-height: 80vh; /* Prevent modal from exceeding viewport height */
  overflow-y: auto; /* Scrollable content if it exceeds the height */
}

/* Full-Page View Styles */
.full-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.full-page-container .popup-content {
  width: 100%;
  max-width: 600px;
  max-height: none;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease;
  overflow: auto;
}

/* Responsive Adjustments for Full-Page View */
@media (max-width: 768px) {
  .full-page-container .popup-content {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .full-page-container .popup-content {
    padding: 1rem;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
