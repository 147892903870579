/* Hero Background */
.financing-hero-background {
    background-image: url("../../assets/heroimage.svg"); /* Ensure correct path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Place behind all content */
    opacity: 0.9; /* Slight transparency for better contrast */
  }
  
  /* General Container Styling */
  .financing-container {
    padding: 8rem 2rem 2rem; /* Adjusted top padding for better layout */
    font-family: "Arial", sans-serif;
    color: #333;
    line-height: 1.8;
    position: relative;
    z-index: 1; /* Ensure content appears above the background */
    background: none; /* No additional background color, hero handles visuals */
    min-height: 100vh; /* Ensure full height for proper footer alignment */
    display: flex;
    flex-direction: column;
  }
  
  /* Header Section */
  .financing-header {
    text-align: center;
    background: linear-gradient(135deg, #e6f0ff, #d8e8ff);
    padding: 1.5rem;
    border-radius: 12px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .financing-header h1 {
    font-size: 2.5rem;
    color: #0046d5;
    margin: 0 0 1rem 0;
  }
  
  .header-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  /* Text Sections Layout */
  .financing-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem; /* Spacing between cards */
    justify-content: center; /* Center-align cards */
  }
  
  .side-by-side-sections {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between; /* Space between the two cards */
    margin-bottom: 2rem; /* Add spacing below the row */
  }
  
  .text-section {
    background: #ffffff;
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1); /* Subtle 3D effect */
    padding: 2rem;
    border-radius: 12px;
    flex: 1; /* Ensure equal width */
    min-width: 300px; /* Ensure cards don't shrink too small */
    max-width: 48%; /* Prevent cards from being too wide in side-by-side layout */
  }
  
  .text-section.full-width {
    max-width: 100%; /* Full width for the last card */
    text-align: center; /* Center-align the content */
  }
  
  .text-section h2 {
    font-size: 2rem;
    color: #0046d5;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .text-section p {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #555;
    text-align: center;
  }
  
  .text-section ul {
    list-style-type: none; /* Remove default bullet points */
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .text-section li {
    font-size: 1.2rem;
    color: #555;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
  }
  
  .text-section li::before {
    content: "✔";
    color: #0046d5;
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  
  /* Call to Action Section */
  .cta-section {
    text-align: center;
  }
  
  .cta-section .cta-button {
    background: linear-gradient(45deg, #0046d5, #1d82ff); /* Match gradient style */
    color: #fff;
    text-decoration: none; /* Remove underline */
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 12px;
    display: inline-block;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Consistent shadow style */
  }
  
  .cta-section .cta-button:hover {
    background: linear-gradient(45deg, #003bb3, #1a6dcc); /* Darker hover gradient */
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0 6px 14px rgba(0, 0, 0, 0.2); /* Enhance shadow on hover */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .financing-content {
      flex-direction: column;
      gap: 1rem; /* Reduce spacing between sections */
    }
  
    .side-by-side-sections {
      flex-direction: column; /* Stack sections vertically on smaller screens */
    }
  
    .text-section {
      max-width: 100%; /* Make each section full-width on smaller screens */
    }
  
    .header-buttons {
      flex-direction: column;
      gap: 1rem;
    }
  }
  