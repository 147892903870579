/* Footer Container */
.footer {
  width: 100%; /* Stretch across the screen */
  background: #f9fbff;
  padding: 2rem 2rem; /* Internal padding */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-family: Arial, sans-serif;
  color: #333;
  box-sizing: border-box; /* Ensure padding is included in the width */
  margin: 0; /* Reset any default margin */
}

/* Footer Top Section */
.footer-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
  flex-wrap: wrap; /* Make it wrap on smaller screens */
}

/* Footer Left Section */
.footer-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  width: 40px;
  margin-bottom: 1rem;
}

.footer-prompt {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subscription-form {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.subscription-input {
  padding: 0.8rem 1rem;
  border: 1px solid #ccc;
  border-radius: 50px 0 0 50px;
  flex: 1;
  font-size: 1rem;
  color: #333;
  outline: none;
}

.subscription-input::placeholder {
  color: #aaa;
}

.subscription-button {
  background: #0046d5;
  color: #fff;
  border: none;
  border-radius: 0 50px 50px 0;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.subscription-button:hover {
  background: #003bb5;
}

/* Footer Links Section */
.footer-links {
  flex: 2;
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  flex-wrap: wrap; /* Make links responsive */
}

.link-column {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.link-column a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.link-column a:hover {
  color: #0046d5;
}

/* Footer Bottom Section */
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 1rem;
  font-size: 0.9rem;
  color: #555;
  flex-wrap: wrap; /* Ensure items wrap on small screens */
  width: 100%; /* Ensure full width */
}

/* Social Icons */
.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icon {
  font-size: 1.2rem;
  color: #555;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #0046d5;
}

/* Responsiveness */
@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
    gap: 2rem;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}
