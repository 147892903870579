/* Hero Background */
.products-hero-background {
  background-image: url("../../assets/heroimage.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place behind all content */
  opacity: 0.9; /* Slight transparency for better contrast */
}

/* Products Header */
.products-header {
  text-align: center;
  background: linear-gradient(135deg, #e6f0ff, #d8e8ff); /* Blue gradient background */
  padding: 1rem; /* Reduce padding for a more compact design */
  border-radius: 8px; /* Slightly less rounded corners */
  margin-bottom: 1.5rem; /* Reduce bottom margin */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Slightly softer shadow */
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .products-content-container {
    padding: 3rem 1rem; /* Reduce padding for smaller screens */
    margin-top: 3rem; /* Add minimal spacing from the top for mobile */
  }

  .products-header {
    margin-top: 1rem; /* Minimal spacing specifically for the header */
  }
}

/* Products Content Container */
.products-content-container {
  padding: 5rem 2rem; /* Reduce padding to bring content closer to the top */
  position: relative;
  z-index: 1; /* Ensure it's above the hero background */
  margin-top: 3rem; /* Smaller margin from the top */
}



.products-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0046d5;
  margin-bottom: 1rem;
}

.products-description {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  color: #555;
}

/* Filter Section */
.products-filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.products-search-bar {
  padding: 0.8rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 300px;
  transition: box-shadow 0.3s ease;
}

.products-search-bar:focus {
  box-shadow: 0 0 8px rgba(0, 70, 213, 0.5);
  outline: none;
}

.products-tag-filter {
  padding: 0.8rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
  transition: box-shadow 0.3s ease;
}

.products-tag-filter:focus {
  box-shadow: 0 0 8px rgba(0, 70, 213, 0.5);
  outline: none;
}

/* Products Grid */
.products-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  padding: 2rem 1rem;
}

/* Individual Product Card */
.products-card {
  background: linear-gradient(135deg, #e6f0ff, #d8e8ff); /* Soft blue gradient */
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  padding: 1.5rem;
  width: 300px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.products-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.products-image {
  width: 100%;
  height: 150px;
  object-fit: contain;
  margin-bottom: 1rem;
}

.products-name {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #0046d5;
}

.products-description {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #666;
}

/* Buttons Section */
.products-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.products-learn-more,
.products-get-started {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  border: none;
}

.products-learn-more {
  background: linear-gradient(45deg, #e0e0e0, #ffffff);
  color: #333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.products-learn-more:hover {
  background: linear-gradient(45deg, #d6d6d6, #f0f0f0);
  transform: scale(1.05);
}

.products-get-started {
  background: linear-gradient(45deg, #0046d5, #1d82ff);
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.products-get-started:hover {
  background: linear-gradient(45deg, #003bb3, #1a6dcc);
  transform: scale(1.05);
}

/* Modal Styling */
/* See your existing modal styles; they're already well-polished */

/* Responsive Design */
@media (max-width: 768px) {
  .products-content-container {
    padding: 5rem 1rem; /* Reduce padding for smaller screens */
  }

  .products-filter-container {
    flex-direction: column;
    gap: 1rem;
  }

  .products-card {
    width: 90%;
  }

  .products-title {
    font-size: 2rem;
  }

  .products-description {
    font-size: 1rem;
  }

  .products-buttons {
    flex-direction: column;
    gap: 0.8rem;
  }
}
/* Sign Up Free Now Button */
.products-signup-button {
  background: linear-gradient(45deg, #0046d5, #1d82ff); /* Vibrant blue gradient */
  color: #fff;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 12px; /* Slightly more rounded for emphasis */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin: 2rem auto; /* Center the button with spacing */
  display: block; /* Ensure it spans the full width if necessary */
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.products-signup-button:hover {
  background: linear-gradient(45deg, #003bb3, #1a6dcc); /* Darker blue on hover */
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* More pronounced shadow */
}

/* Center the button for specific sections */
.centered-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem; /* Space above */
}
/* Modal Container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal Content */
.modal-content {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25); /* Stronger shadow for depth */
  padding: 2rem;
  width: 90%;
  max-width: 700px;
  position: relative;
  animation: slideUp 0.4s ease-in-out;
  text-align: left;
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 10px; /* Adjust for spacing at the top */
  right: 10px; /* Adjust for spacing at the right */
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  transition: color 0.2s ease, background-color 0.2s ease;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; /* Circular button for aesthetics */
}

.modal-close:hover {
  color: #0046d5;
  background-color: rgba(0, 70, 213, 0.1); /* Subtle hover background */
}

/* Modal Header */
.modal-content h2 {
  font-size: 2.2rem;
  color: #0046d5;
  margin-bottom: 1.5rem;
  font-weight: bold;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 0.5rem;
}

/* Modal Subheading */
.modal-content h3 {
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #222;
  font-weight: 600;
  margin-bottom: 0.75rem;
}

/* Modal Description */
.modal-content p {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #555;
  line-height: 1.6;
}

/* Modal Features List */
.modal-content ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.modal-content ul li {
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #555;
  display: flex;
  align-items: center;
}

.modal-content ul li::before {
  content: '✔';
  color: #0046d5;
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

/* Modal Buttons */
.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.modal-learn-more,
.modal-get-started {
  padding: 0.8rem 1.8rem;
  font-size: 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal-learn-more {
  background: linear-gradient(45deg, #e0e0e0, #f9f9f9);
  color: #333;
}

.modal-learn-more:hover {
  background: linear-gradient(45deg, #d6d6d6, #f1f1f1);
  transform: scale(1.05);
}

.modal-get-started {
  background: linear-gradient(45deg, #0046d5, #1d82ff);
  color: #fff;
}

.modal-get-started:hover {
  background: linear-gradient(45deg, #003bb3, #1a6dcc);
  transform: scale(1.05);
}

.products-signup-button {
  display: inline-block; /* Ensures it doesn't span the full width */
  padding: 10px 20px; /* Adds some padding for a button-like look */
  background-color: #007BFF; /* Button background color */
  color: #ffffff; /* Text color */
  text-decoration: none; /* Removes underline for links */
  border: none; /* Removes default button border */
  border-radius: 5px; /* Adds rounded corners */
  font-size: 16px; /* Adjusts text size */
  font-weight: bold; /* Makes text bold */
  text-align: center; /* Centers text inside the button */
  cursor: pointer; /* Shows pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth hover effect */
}

.products-signup-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
.products-get-started {
  display: inline-block;
  padding: 12px 24px;
  background-color: #0056d2;
  color: white;
  text-decoration: none;
  font-weight: bold;
  border-radius: 8px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.products-get-started:hover {
  background-color: #0041a8;
  transform: scale(1.05);
}
