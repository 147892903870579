/* Base Button Styles */
.button {
  padding: 0.6rem 1.2rem; /* Reduced width and increased height */
  border-radius: 20px; /* Increased border radius for rounded buttons */
  font-weight: 600;
  cursor: pointer;
  font-size: 0.95rem; /* Slightly smaller font size for a modern look */
  transition: all 0.3s ease;
  text-align: center;
  text-decoration: none; /* Remove underline for links styled as buttons */
}

/* Style 1: "Get a Free Quote" Button */
.button-outline {
  background-color: #ffffff;
  color: #0056d2;
  border: 2px solid #0056d2;
}

.button-outline:hover {
  background-color: #0056d2;
  color: #ffffff;
}

/* Style 2: "Request Consultation" Button */
.button-primary {
  background: linear-gradient(90deg, #4facfe, #0056d2); /* Light blue to dark blue gradient */
  color: #ffffff;
  border: none;
}

.button-primary:hover {
  background: linear-gradient(90deg, #3a8dfe, #0041a8); /* Hover gradient transition */
}

/* Style 3: "Call Now" Button */
.button-black {
  background-color: #000000;
  color: #ffffff;
  border: none;
}

.button-black:hover {
  background-color: #333333;
}

/* Style 4: "Log In" Button */
.button-gradient {
  background: linear-gradient(90deg, #3a8dfe, #0056d2); /* Light blue to dark blue gradient */
  color: #ffffff;
  border: none;
}

.button-gradient:hover {
  background: linear-gradient(90deg, #4facfe, #00308c); /* Hover gradient with lighter transition */
}
