/* Main Header Styles */
.main-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure logo and menu align properly */
    padding: 2rem 6rem;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 13rem); /* Adjust width for padding */
    background-color: transparent;
    z-index: 10;
    font-family: 'Inter', sans-serif;
}

.main-header.menu-open {
    background-color: rgba(0, 0, 0, 0.8); /* Background when menu is open */
}

/* Main Header Logo Section */
.main-header-logo-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-right: 2rem; /* Add spacing between logo and nav links */
}

.main-header-logo {
    height: 80px;
    width: auto;
}

/* Main Header Navigation Links (Desktop) */
.main-header-nav-links {
    display: flex;
    gap: 2rem;
    margin-left: 0; 
    align-items: center;
}

/* Individual Nav Links */
.main-header-nav-link {
    text-decoration: none;
    color: #000; /* Black text color */
    font-size: 1.4rem;
    font-weight: 400;
    transition: color 0.3s ease; /* Smooth hover effect */
}

.main-header-nav-link:hover {
    color: #0070f3; /* Subtle blue hover effect */
}

/* Main Header Buttons (Desktop) */
.main-header-button-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.main-header-button {
    padding: 0.8rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 600;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.main-header-button-black {
    background-color: #000;
    color: #fff;
}

.main-header-button-black:hover {
    background-color: #333;
}

.main-header-button-gradient {
    background: linear-gradient(90deg, #0070f3, #0056c1);
    color: #fff;
    text-decoration: none; /* Remove underline for link buttons */
    display: inline-block; /* Ensure consistent button styling */
    text-align: center;
}

.main-header-button-gradient:hover {
    background: linear-gradient(90deg, #0056c1, #0070f3);
}

/* Main Header Hamburger Menu */
.main-header-hamburger-menu {
    display: none;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.main-header-hamburger-menu .main-header-bar {
    width: 55px;
    height: 3px;
    background-color: #000;
    border-radius: 3px;
}

/* Main Header Mobile Menu Overlay */
.main-header-mobile-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

/* Main Header Mobile Navigation for Smaller Screens */
@media (max-width: 1024px) {
    .main-header {
        padding: 1.5rem 2rem; /* Adjust padding for tablets */
        justify-content: space-between;
    }

    .main-header-nav-links {
        display: none; /* Hide desktop nav links */
    }

    .main-header-nav-links.active {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 80px; /* Align below the header */
        right: 2rem; /* Align dropdown to the right */
        background-color: #ffffff; /* Clean white background */
        width: 280px; /* Constrain width */
        padding: 1rem;
        gap: 1rem;
        z-index: 10;
        border-radius: 8px; /* Rounded corners for a modern look */
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for separation */
    }

    .main-header-nav-link {
        text-decoration: none;
        color: #333; /* Dark text for contrast */
        font-size: 1.6rem;
        font-weight: 500;
        padding: 0.8rem 1.2rem; /* Add padding for touch targets */
        border-radius: 4px; /* Rounded corners for hover effect */
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .main-header-nav-link:hover {
        background-color: rgba(7, 115, 238, 0.1); /* Light blue background on hover */
        color: #0070f3; /* Blue text on hover */
    }

    .main-header-button-container {
        display: none; /* Hide desktop buttons */
    }

    .main-header-dropdown-buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem; /* Separate from nav links */
        border-top: 1px solid rgba(0, 0, 0, 0.1); /* Top border for separation */
    }

    .main-header-dropdown-buttons .main-header-button {
        padding: 0.8rem 1.5rem;
        font-size: 1.2rem;
        font-weight: 600;
        text-align: center;
        border-radius: 4px;
    }

    .main-header-hamburger-menu {
        display: flex; /* Show hamburger menu */
    }
}

/* Mobile Hamburger Menu for Screens Below 768px */
@media (max-width: 768px) {
    .main-header-nav-links.active {
        width: 240px; /* Slightly smaller menu width */
        padding: 1rem; /* Adjust padding */
        top: 60px; /* Align below header */
        right: 1rem; /* Align with edge of screen */
    }
}
