/* Contact Hero Background */
.contact-hero-background {
  background-image: url("../../assets/heroimage.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place behind all content */
  opacity: 0.9; /* Slight transparency for better contrast */
}

/* Contact Page */
.contact-page {
  font-family: Arial, sans-serif;
  color: #333;
  position: relative;
}

/* Contact Content Container */
.contact-content-container {
  padding: 10rem 10rem; /* Padding around the content */
}

/* Contact Form Container */
.contact-form-container {
  background: rgba(255, 255, 255, 0.95); /* Slight transparency */
  padding: 3rem 3.5rem;
  border-radius: 20px; /* More rounded edges for modern look */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); /* Stronger shadow for 3D effect */
  max-width: 600px;
  margin: 0 auto; /* Center form container */
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-form-container:hover {
  transform: translateY(-5px); /* Subtle lift on hover */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25); /* Enhanced shadow for hover effect */
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #0046d5;
  margin-bottom: 1rem;
}

.contact-description {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem; /* Space between form fields */
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-size: 1rem;
  font-weight: bold;
  color: #0046d5;
  margin-bottom: 0.5rem;
}

/* Input and Textarea Styles */
.form-group input,
.form-group textarea {
  font-size: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 12px; /* Rounded edges */
  outline: none;
  box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1); /* Inner shadow for 3D effect */
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  box-shadow: inset 0 6px 10px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 70, 213, 0.5);
  border-color: #0046d5;
}

/* Submit Button */
.submit-button {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(45deg, #000000, #0046d5); /* Black-to-blue gradient */
  border: none;
  padding: 1rem 2rem;
  border-radius: 12px; /* Rounded button */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Button shadow for depth */
}

.submit-button:hover {
  background: linear-gradient(135deg, #ffffff, #0056d5); /* Darker hover gradient */
  transform: translateY(-3px); /* Lift effect on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.submit-button:active {
  transform: translateY(1px); /* Subtle press effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Softer shadow on click */
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-content-container {
    padding: 9rem 1rem;
  }

  .contact-form-container {
    padding: 2rem;
  }

  .submit-button {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
}
