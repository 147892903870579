/* Hero Section */
.hero-section {
  position: relative;
  display: flex;
  justify-content: space-between; /* Align text and cards horizontally */
  align-items: center; /* Vertically center both columns */
  text-align: left; /* Left-align text content */
  padding: 5rem 5rem; /* Increase top padding to push section down */
  background-image: url("../../assets/heroimage.svg"); /* Set hero image as background */
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent tiling */
  height: 100vh; /* Full-screen height */
  color: #333; /* Adjusted text color for better contrast */
  overflow: hidden; /* Prevent overflow of content */
}

/* Hero Content */
.hero-content {
  flex: 1;
  max-width: 60%; /* Increase max width to allow more space for text */
  z-index: 2; /* Ensure content appears above background */
  padding: 1rem;
}

.hero-content h1 {
  font-size: 4rem; /* Keep the large font size */
  line-height: 1.2;
  margin-bottom: 1rem;
  color: #0056d2; /* Primary blue */
  white-space: nowrap; /* Prevent wrapping (optional) */
}

.hero-content p {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  color: #555; /* Subtle color for contrast */
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.hero-buttons .button {
  font-size: 1.2rem;
  padding: 0.9rem 1.8rem;
}

/* Stars and Testimonial Section */
.hero-testimonial {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align content to the left */
}

.hero-testimonial .stars {
  display: flex; /* Use flexbox to align stars */
  justify-content: flex-start; /* Align stars to the left */
  gap: 0.5rem; /* Add spacing between stars */
  font-size: 1.5rem; /* Adjust star size */
  color: #ffd700; /* Gold color for stars */
  margin-bottom: 0.5rem; /* Add spacing below stars */
}

.hero-testimonial .testimonial-text {
  font-size: 1rem;
  color: #666; /* Subtle color for text */
  line-height: 1.4;
}



/* Hero Cards Container */
.hero-cards {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; /* Center cards horizontally */
  width: 50%; /* Cards take half the section width */
  margin-left: 5rem; /* Push the cards to the right */
  height: 400px; /* Controls overall height of the card stack */
  z-index: 1; /* Positioned below text content */
}

/* Common Card Styles */
.hero-card {
  position: absolute;
  width: 300px;
  padding: 1.5rem;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


/* Card 1 (Top - White) */
.card-1 {
  background: #ffffff;
  top: -75px; /* Adjust vertical position */
  left: 80px; /* Adjust horizontal position */
  z-index: 2;
  width: 280px; /* Adjusted width of the card for better proportions */
  height: 210px; /* Adjusted height of the card for better proportions */
  border-radius: 16px; /* Keeps rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 10px; /* Add equal padding on all sides */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center the graph vertically */
  align-items: center; /* Center the graph horizontally */
  text-align: center; /* Center align any additional content */
}

/* Landing Page Graph */
.landing-page-graph {
  width: 90%; /* Adjusted width to leave space for padding */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the graph scales proportionally */
  transform: scale(1) translate(0, 0); /* Remove scaling for better fit */
  margin: 0 auto; /* Center the graph horizontally */
  position: relative; /* Allow for controlled positioning inside the card */
}

.card-1 h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333; /* Professional dark color */
}

.card-1 p {
  font-size: 1rem;
  margin: 0;
  color: #666; /* Subtle gray for secondary text */
}

/* Card 2 (Middle - Light Blue) */
.card-2 {
  background: #ffffff;
  top: 180px; /* Adjust vertical position */
  left: 80px; /* Adjust horizontal position */
  z-index: 2;
  width: 480px; /* Set the width of the card */
  height: 300px; /* Set the height of the card */
  border-radius: 16px; /* Keeps rounded corners */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 1.5rem; /* Inner padding */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute content evenly */
}

.card-2 h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #d1d1d1;
}

.card-2 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Add spacing between list items */
}

.card-2 ul li {
  font-size: 1rem;
  color: #d1d1d1;
  padding: 0.5rem 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align list items */
}

.card-2 .button {
  margin-top: 1rem;
  align-self: center; /* Center the button horizontally */
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #a3c2ff;
  border: 1px solid #a3c2ff;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.card-2 .button:hover {
  background: #0046d5;
  color: #fff;
}

/* Card 3 (Bottom - Blue with Profile) */
.card-3 {
  background: linear-gradient(135deg, #003459, #0046d5);
  color: #ffffff;
  top: -55px; /* Adjust vertical position */
  left: 400px; /* Adjust horizontal position */
  z-index: 3;
  width: 280px; /* Set the width of the card */
  height: 280px; /* Set the height of the card */
  border-radius: 16px; /* Keeps rounded corners */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
  padding: 1.5rem; /* Inner padding */
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Distribute content evenly */
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-3 h3 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.card-3 p {
  font-size: 1rem;
  margin: 0.5rem 0;
  color: #d1e0ff;
}

.card-3 .profile-image-container {
  margin-bottom: 0; /* Remove margin */
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  overflow: hidden; /* Hide overflow for zoomed images */
  width: 120px; /* Match profile image size */
  height: 120px; /* Match profile image size */
  border-radius: 50%; /* Keep it circular */
  position: relative; /* Enable positioning for better control */
}

.card-3 .profile-image {
  width: 130%; /* Increase the size of the image */
  height: 130%; /* Increase the size of the image */
  object-fit: cover; /* Ensure the image scales properly */
  object-position: center; /* Keep the focal point in the center */
  position: absolute; /* Allow for controlled positioning */
}


/* For screens between 1280px and 1670px */
@media (min-width: 1280px) and (max-width: 1670px) {
  /* Hero Section */
  .hero-section {
    position: relative;
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center-align all content */
    text-align: center; /* Center-align text content */
    padding: 6rem 2rem 14rem 2rem; /* Top, Right, Bottom, Left padding */
    background-image: url("../../assets/heroimage.svg"); /* Background image */
    background-size: cover; /* Cover the entire section */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent tiling */
    min-height: 100vh; /* Ensure the section takes at least full screen height */
    color: #333; /* Adjusted text color for better contrast */
  }
  

  .hero-content {
    max-width: 85%; /* Allow more space for the text */
    padding: 3rem 1.5rem; /* Maintain consistent spacing */
  }

  .hero-content h1 {
    font-size: 3.5rem; /* Adjust font size */
    word-wrap: break-word; /* Ensure text wraps properly */
  }

  .hero-content p {
    font-size: 1.4rem; /* Adjust paragraph font size */
    margin-bottom: 2rem;
  }

  .hero-buttons {
    flex-direction: row; /* Keep buttons side by side */
    justify-content: center; /* Center buttons */
    gap: 1.5rem; /* Add spacing between buttons */
  }

  .hero-buttons .button {
    padding: 1rem 2rem; /* Adjust button size */
    font-size: 1.1rem;
  }

  .hero-testimonial {
    margin-top: 2rem; /* Add spacing above testimonials */
    align-items: center; /* Center-align testimonial content */
  }

  .hero-testimonial .stars {
    gap: 0.5rem; /* Adjust spacing between stars */
    font-size: 1.5rem; /* Adjust star size */
  }

  .hero-testimonial .testimonial-text {
    font-size: 1rem;
    text-align: center; /* Center-align text */
  }

  /* Hero Cards Container */
.hero-cards {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack cards vertically */
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Center cards horizontally */
  width: 100%; /* Full width of the container */
  gap: 2rem; /* Add spacing between cards */
  padding: 2rem; /* Add breathing space around */
}

/* Individual Card Styles */
.hero-card {
  border-radius: 12px; /* Keep corners rounded */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  padding: 1.5rem; /* Inner padding for content */
  background-color: #ffffff; /* Default card background color */
  transition: all 0.3s ease; /* Add smooth transitions for hover effects */
}

  .card-1 {
    background: #ffffff;
    top: -35px; /* Adjust vertical position */
    left: 46%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;
    width: 280px; /* Adjusted width of the card for better proportions */
    height: 210px; /* Adjusted height of the card for better proportions */
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
  

  /* Adjustments for Card 2 */
  .hero-card.card-2 {
    width: 550px; /* Adjust card width */
    height: auto; /* Allow dynamic height */
    background-color: #eaf4ff; /* Set light blue background */
    top: 210px; /* Adjust vertical position */
    left: 65%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;   
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
/* Style for the unordered list */
.hero-card.card-2 ul {
  list-style: none; /* Remove default bullets */
  padding: 0; /* Remove default padding */
  margin: 0 auto; /* Center the list */
  width: 80%; /* Make the list span 80% of the card width */
  display: flex;
  flex-direction: column; /* Stack list items vertically */
  gap: 0.8rem; /* Add space between list items */
}

/* Style for the list items */
.hero-card.card-2 ul li {
  font-size: 1rem; /* Adjust font size */
  color: #555; /* Text color */
  padding: 0.6rem 1rem; /* Inner padding for better appearance */
  background: #f0f4fc; /* Light blue background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: left; /* Align text to the left */
  width: 100%; /* Make each list item span the full list width */
}

/* Button styles inside card 2 */
.hero-card.card-2 .button {
  margin-top: 1.5rem; /* Add spacing above the button */
  align-self: center; /* Center the button horizontally */
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #0046d5;
  border: 1px solid #0046d5;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero-card.card-2 .button:hover {
  background: #0046d5;
  color: #fff;
}


  
  /* Adjustments for Card 3 */
  .hero-card.card-3 {
    width: 250px; /* Adjust card width */
    height: auto; /* Allow dynamic height */
    background-color: #f0f8f5; /* Set light green background */
    top: -5px; /* Adjust vertical position */
    left: 70%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
}




/* For screens between 1025px and 1280px (medium-sized desktops and large tablets in landscape) */
@media (max-width: 1280px) and (min-width: 1025px) {
  .hero-section {
    flex-direction: column; /* Stack content vertically for better alignment */
    text-align: center; /* Center-align content */
    padding: 4rem 2rem; /* Add more padding for better spacing */
    height: auto; /* Ensure content dictates height */
  }

  .hero-content {
    max-width: 85%; /* Allow more space for content */
    padding: 3rem 1.5rem; /* Maintain consistent spacing */
  }

  .hero-content h1 {
    font-size: 3.5rem; /* Adjust font size slightly smaller than full desktop */
    word-wrap: break-word; /* Ensure text wraps */
  }

  .hero-content p {
    font-size: 1.4rem; /* Slightly smaller for better fit */
    margin-bottom: 2rem;
  }

  .hero-buttons {
    flex-direction: row; /* Keep buttons side by side */
    justify-content: center; /* Center buttons */
    gap: 1.5rem; /* Add spacing between buttons */
  }

  .hero-buttons .button {
    padding: 1rem 2rem; /* Adjust button size */
    font-size: 1.1rem;
  }

  .hero-testimonial {
    margin-top: 2rem; /* Add spacing above testimonials */
    align-items: center; /* Center-align content */
  }

  .hero-testimonial .stars {
    gap: 0.5rem; /* Slightly reduce gap between stars */
    font-size: 1.5rem; /* Adjust size for better spacing */
  }

  .hero-testimonial .testimonial-text {
    font-size: 1rem;
    text-align: center; /* Center-align text */
  }

  /* Hero Cards Container */
.hero-cards {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack cards vertically */
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Center cards horizontally */
  width: 100%; /* Full width of the container */
  gap: 2rem; /* Add spacing between cards */
  padding: 2rem; /* Add breathing space around */
}

/* Individual Card Styles */
.hero-card {
  border-radius: 12px; /* Keep corners rounded */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  padding: 1.5rem; /* Inner padding for content */
  background-color: #ffffff; /* Default card background color */
  transition: all 0.3s ease; /* Add smooth transitions for hover effects */
}

  .card-1 {
    background: #ffffff;
    top: -35px; /* Adjust vertical position */
    left: 46%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;
    width: 280px; /* Adjusted width of the card for better proportions */
    height: 210px; /* Adjusted height of the card for better proportions */
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
  

  /* Adjustments for Card 2 */
  .hero-card.card-2 {
    width: 550px; /* Adjust card width */
    height: auto; /* Allow dynamic height */
    background-color: #eaf4ff; /* Set light blue background */
    top: 210px; /* Adjust vertical position */
    left: 70%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;   
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
/* Style for the unordered list */
.hero-card.card-2 ul {
  list-style: none; /* Remove default bullets */
  padding: 0; /* Remove default padding */
  margin: 0 auto; /* Center the list */
  width: 80%; /* Make the list span 80% of the card width */
  display: flex;
  flex-direction: column; /* Stack list items vertically */
  gap: 0.8rem; /* Add space between list items */
}

/* Style for the list items */
.hero-card.card-2 ul li {
  font-size: 1rem; /* Adjust font size */
  color: #555; /* Text color */
  padding: 0.6rem 1rem; /* Inner padding for better appearance */
  background: #f0f4fc; /* Light blue background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: left; /* Align text to the left */
  width: 100%; /* Make each list item span the full list width */
}

/* Button styles inside card 2 */
.hero-card.card-2 .button {
  margin-top: 1.5rem; /* Add spacing above the button */
  align-self: center; /* Center the button horizontally */
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #0046d5;
  border: 1px solid #0046d5;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero-card.card-2 .button:hover {
  background: #0046d5;
  color: #fff;
}


  
  /* Adjustments for Card 3 */
  .hero-card.card-3 {
    width: 250px; /* Adjust card width */
    height: auto; /* Allow dynamic height */
    background-color: #f0f8f5; /* Set light green background */
    top: -5px; /* Adjust vertical position */
    left: 78%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
}



/* For screens smaller than 1024px (tablets) */
@media (max-width: 1024px) {
  .hero-section {
    flex-direction: column; /* Stack content vertically */
    text-align: center; /* Center-align text content */
    padding: 3rem 1rem; /* Match the top padding as provided */
    height: auto; /* Allow content to define height */
  }

  .hero-content {
    max-width: 90%; /* Allow more space for text */
    padding: 3rem 1rem; /* Maintain consistent padding */
  }

  .hero-content h1 {
    font-size: 3rem; /* Adjust font size */
    word-wrap: break-word; /* Ensure long words break */
  }

  .hero-content p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .hero-buttons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .hero-buttons .button {
    padding: 0.8rem 1.5rem; /* Smaller buttons */
    font-size: 1rem;
  }

  .hero-testimonial {
    align-items: center; /* Center-align stars and text */
    margin-top: 2rem;
  }

  .hero-testimonial .stars {
    justify-content: center; /* Center stars horizontally */
    gap: 0.4rem;
    font-size: 1.3rem;
  }

  .hero-testimonial .testimonial-text {
    font-size: 0.9rem;
    text-align: center;
  }

  .hero-cards {
    display: none;
  }
}

/* For screens smaller than 768px (mobile devices) */
@media (max-width: 768px) {
  .hero-section {
    padding: 6rem 2rem 10rem 2rem; /* Top, Right, Bottom, Left padding */
  }

  .hero-content {
    max-width: 95%; /* Ensure it stays within viewport */
    padding: 3rem 1rem; /* Maintain consistency */
  }

  .hero-content h1 {
    font-size: 2.5rem; /* Smaller font size */
    line-height: 1.2; /* Adjust line height for tighter spacing */
    word-wrap: break-word; /* Ensure text wraps */
  }

  .hero-content p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .hero-buttons {
    justify-content: center; /* Center-align buttons */
    gap: 1rem;
  }

  .hero-buttons .button {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem; /* Compact button padding */
  }

  /* Hero Cards Container */
.hero-cards {
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack cards vertically */
  justify-content: flex-start; /* Align items to the start */
  align-items: center; /* Center cards horizontally */
  width: 100%; /* Full width of the container */
  gap: 2rem; /* Add spacing between cards */
  padding: 2rem; /* Add breathing space around */
}

/* Individual Card Styles */
.hero-card {
  border-radius: 12px; /* Keep corners rounded */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Add subtle shadow for depth */
  padding: 1.5rem; /* Inner padding for content */
  background-color: #ffffff; /* Default card background color */
  transition: all 0.3s ease; /* Add smooth transitions for hover effects */
}

  .card-1 {
    background: #ffffff;
    top: -35px; /* Adjust vertical position */
    left: 46%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;
    width: 300px; /* Adjusted width of the card for better proportions */
    height: 240px; /* Adjusted height of the card for better proportions */
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
  

  /* Adjustments for Card 2 */
  .hero-card.card-2 {
    width: 550px; /* Adjust card width */
    height: auto; /* Allow dynamic height */
    background-color: #eaf4ff; /* Set light blue background */
    top: 250px; /* Adjust vertical position */
    left: 81.5%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;   
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
/* Style for the unordered list */
.hero-card.card-2 ul {
  list-style: none; /* Remove default bullets */
  padding: 0; /* Remove default padding */
  margin: 0 auto; /* Center the list */
  width: 80%; /* Make the list span 80% of the card width */
  display: flex;
  flex-direction: column; /* Stack list items vertically */
  gap: 0.8rem; /* Add space between list items */
}

/* Style for the list items */
.hero-card.card-2 ul li {
  font-size: 1rem; /* Adjust font size */
  color: #555; /* Text color */
  padding: 0.6rem 1rem; /* Inner padding for better appearance */
  background: #f0f4fc; /* Light blue background */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: left; /* Align text to the left */
  width: 100%; /* Make each list item span the full list width */
}

/* Button styles inside card 2 */
.hero-card.card-2 .button {
  margin-top: 1.5rem; /* Add spacing above the button */
  align-self: center; /* Center the button horizontally */
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #0046d5;
  border: 1px solid #0046d5;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero-card.card-2 .button:hover {
  background: #0046d5;
  color: #fff;
}


  
  /* Adjustments for Card 3 */
  .hero-card.card-3 {
    width: 250px; /* Adjust card width */
    height: auto; /* Allow dynamic height */
    background-color: #f0f8f5; /* Set light green background */
    top: -5px; /* Adjust vertical position */
    left: 85%; /* Start positioning from the center of the screen */
    transform: translateX(-100%); /* Shift the card left by its full width to align the right edge */
    z-index: 2;
    border-radius: 16px; /* Keeps rounded corners */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 10px; /* Add equal padding on all sides */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex; /* Enable flexbox layout */
    justify-content: center; /* Center the graph vertically */
    align-items: center; /* Center the graph horizontally */
    text-align: center; /* Center align any additional content */
  }
}


/* For screens smaller than 480px (small smartphones, including Galaxy Z Fold 5) */
@media (max-width: 680px) {
  .hero-content {
    max-width: 100%; /* Ensure full width usage */
    padding: 1rem 2rem 0rem 2rem; /* Top, Right, Bottom, Left padding */
  }

  .hero-content h1 {
    font-size: 1.8rem; /* Reduce heading size for small screens */
    line-height: 1.3; /* Adjust spacing */
    word-wrap: break-word; /* Ensure text wraps */
    overflow-wrap: break-word; /* Prevent text from overflowing */
  }

  .hero-content p {
    font-size: 0.9rem;
    margin-bottom: 0.8rem;
  }

  .hero-buttons .button {
    font-size: 0.8rem;
    padding: 0.6rem 1rem; /* More compact button size */
  }

  .hero-cards {
    display: none; /* Cards remain hidden */
  }

}






  

/* Who We Are Section */
.who-we-are {
  text-align: center;
  padding: 4rem 2rem;
  background-color: #f9fafc;
}

.section-title {
  font-size: 3rem; /* Smaller font size for smaller screens */
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.section-subtitle {
  font-size: 1.2rem; /* Adjusted for smaller screens */
  margin-bottom: 2rem;
  color: #666;
}

/* Cards Container */
.cards-container {
  display: flex;
  flex-wrap: wrap; /* Allow cards to wrap to new rows on smaller screens */
  justify-content: center;
  align-items: flex-start;
  gap: 2rem; /* Reduce spacing between cards for smaller screens */
  padding: 2rem;
  background: #ffffff; /* White background for the container */
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Individual Cards */
.feature-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  background: transparent;
  padding: 1.5rem;
  border-radius: 12px;
  flex: 1;
  max-width: 300px; /* Limit width for smaller screens */
  min-width: 250px; /* Maintain a minimum width */
  text-align: left;
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.feature-title {
  font-size: 1.2rem; /* Slightly smaller font for mobile */
  font-weight: 600;
  color: #0046d5;
  margin-bottom: 0.5rem;
}

.feature-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

/* Responsive Adjustments */

/* For screens smaller than 1024px (tablets) */
@media (max-width: 1024px) {
  .section-title {
    font-size: 2.5rem; /* Smaller title font */
  }

  .section-subtitle {
    font-size: 1.2rem;
  }

  .cards-container {
    gap: 1.5rem; /* Reduce spacing between cards */
  }

  .feature-card {
    max-width: 90%; /* Cards take up more width on tablets */
  }
}

/* For screens smaller than 768px (mobile devices) */
@media (max-width: 768px) {
  .section-title {
    font-size: 2rem; /* Smaller title font for mobile */
  }

  .section-subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .cards-container {
    padding: 1rem; /* Reduce padding for smaller screens */
    gap: 1rem;
  }

  .feature-card {
    max-width: 100%; /* Cards take full width */
    min-width: unset; /* Allow flexibility in width */
    text-align: center; /* Center-align content */
  }

  .feature-title {
    font-size: 1.1rem;
  }

  .feature-description {
    font-size: 0.9rem;
  }

  .feature-icon {
    align-self: center; /* Center the icon */
    margin-bottom: 0.5rem;
  }
}

/* For screens smaller than 480px (small smartphones) */
@media (max-width: 480px) {
  .section-title {
    font-size: 1.8rem; /* Smaller title font */
  }

  .section-subtitle {
    font-size: 0.9rem;
  }

  .cards-container {
    padding: 0.5rem; /* Minimal padding for very small screens */
    gap: 0.8rem;
  }

  .feature-card {
    padding: 1rem; /* Reduce card padding */
  }

  .feature-title {
    font-size: 1rem;
  }

  .feature-description {
    font-size: 0.8rem;
  }
}










/* TRUSTED BY SECTION */

/* Slider Container */
.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
}

.slider {
  display: flex;
  overflow: hidden;
  width: 80%;
  max-width: 1000px;
  position: relative;
}

.slider-card {
  flex: 0 0 30%; /* Show 3 cards at a time */
  height: 150px; /* Ensure uniform height for cards */
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center; /* Center the logo in the card */
  margin: 0 0.5rem;
  cursor: pointer;
}

.slider-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.slider-logo {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain; /* Ensures logo fits nicely within the card */
}

/* Slider Buttons */
.slider-button {
  background: #0046d5;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  position: absolute;
  z-index: 2; /* Ensure buttons are above the slider content */
}

.slider-button:hover {
  background: #003bb3;
  transform: scale(1.1);
}

.slider-button.prev {
  left: 20px; /* Add padding from the left edge */
}

.slider-button.next {
  right: 20px; /* Add padding from the right edge */
}

/* Responsive Design for Slider Buttons */
@media (max-width: 768px) {
  .slider-button {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }

  .slider-button.prev {
    left: 10px; /* Adjust padding for smaller screens */
  }

  .slider-button.next {
    right: 10px; /* Adjust padding for smaller screens */
  }
}



/* Testimonials Section */
.testimonials {
  background: linear-gradient(to bottom, #f9fcff, #e6f0ff); /* Subtle outer gradient */
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Testimonial Container */
.testimonial-container {
  background: linear-gradient(145deg, #1e75e4, #153a96); /* Vibrant blue gradient with a modern touch */
  color: #fff;
  border-radius: 32px; /* More rounded edges */
  padding: 3rem 4rem; /* Adequate inner padding for spacing */
  width: 100%; /* Stretch to full width */
  max-width: 1200px; /* Prevent over-stretching */
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2), 0 -4px 12px rgba(255, 255, 255, 0.1); /* More pronounced shadow */
  position: relative;
  overflow: hidden; /* Ensures no content overflows */
}

/* Title Styling */
.testimonial-title {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle text shadow */
}

/* Content Styling */
.testimonial-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap; /* Ensure responsiveness for smaller screens */
}

/* Text Container */
.testimonial-text-container {
  flex: 1;
  text-align: left;
}

.testimonial-text {
  font-size: 1.4rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: #e6eaff; /* Softer white for readability */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.testimonial-name {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.testimonial-position {
  font-size: 1.2rem;
  color: #c1d3f0;
  font-weight: 600;
}

/* Image Container */
.testimonial-image-container {
  flex: 0.5;
  display: flex;
  justify-content: center;
}

.testimonial-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 8px solid #fff;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.3), 0 -4px 8px rgba(255, 255, 255, 0.1); /* Enhanced 3D effect */
  transform: scale(1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add hover effect */
}

.testimonial-image:hover {
  transform: scale(1.1); /* Slight zoom on hover */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4); /* Stronger shadow on hover */
}

.testimonial-text-container {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.testimonial-text-container.active {
  display: block;
  opacity: 1;
}

.testimonial-navigation {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.dot.active {
  opacity: 1;
}


/* Responsive Design */
@media (max-width: 768px) {
  .testimonial-container {
    padding: 2rem;
  }

  .testimonial-title {
    font-size: 2rem;
  }

  .testimonial-text {
    font-size: 1.2rem;
  }

  .testimonial-image {
    width: 140px;
    height: 140px;
  }

  .dot {
    width: 12px;
    height: 12px;
  }
}

/* FAQ Section */
.faq-section {
  text-align: center;
  padding: 4rem 2rem;
  background: linear-gradient(to bottom, #f9fcff, #f1f6fb); /* Subtle gradient */
}

/* Container */
.faq-container {
  max-width: 1500px;
  margin: 0 auto;
}

/* Title and Subtitle */
.section-title {
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

.section-subtitle {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 2rem;
}

/* FAQ Items */
.faq {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq-item:hover {
  color: #0046d5; /* Highlight color */
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.question-text {
  flex: 1;
  text-align: left;
}

.toggle-icon {
  font-size: 1.5rem;
  color: #999;
  transition: transform 0.3s ease;
}

.faq-item.active .toggle-icon {
  transform: rotate(180deg); /* Rotate when active */
  color: #0046d5; /* Match hover color */
}

/* FAQ Answer */
.faq-answer {
  margin-top: 1rem;
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  text-align: left;
}
