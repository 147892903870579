.privacy-policy {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
}

.privacy-header {
    text-align: center;
    padding: 10px 0;
    border-bottom: 2px solid #007bff;
}

.privacy-header h1 {
    font-size: 2rem;
    color: #007bff;
}

.privacy-content h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    color: #0056b3;
}

.privacy-content p, 
.privacy-content ul {
    line-height: 1.6;
    margin: 10px 0;
}

.privacy-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

.privacy-content li {
    margin-bottom: 5px;
}

.privacy-content strong {
    color: #333;
}

@media (max-width: 768px) {
    .privacy-policy {
        padding: 15px;
    }

    .privacy-header h1 {
        font-size: 1.5rem;
    }

    .privacy-content h2 {
        font-size: 1.25rem;
    }
}
