/* General Styles */
.about-us-page {
    font-family: "Inter", sans-serif;
    line-height: 1.8;
    color: #333;
    margin: 0;
    padding: 0;
    position: relative;
    background-image: url("../../assets/heroimage.svg");
    background-position: right top; /* Start the background from the far right */
    background-size: cover; /* Ensure the image covers the area */
    background-repeat: no-repeat; /* Prevent tiling */
}

/* Centralized Container for All Sections */
.container {
    max-width: 1200px; /* Set a maximum width to prevent stretching */
    margin: 0 auto; /* Center the container */
    padding: 0 2rem; /* Add padding to the sides */
}

/* Hero Content Section */
.about-us-hero-content {
    text-align: center;
    color: #0046d5;
    padding: 8rem 2rem 4rem; /* Added horizontal padding */
}

.about-us-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    letter-spacing: 0.5px; /* Slight letter-spacing for a refined look */
    text-transform: capitalize;
    color: #0038a8;
}

.about-us-mission {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8;
    color: #444;
    margin-bottom: 2rem; /* Added spacing below mission statement */
}

/* Values Section */
.about-us-values-section {
    background-color: #ffffff; /* Clean white background */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); /* Softer shadow */
    border-radius: 8px;
    padding: 2rem 3rem; /* Added padding inside the container */
    margin-bottom: 2rem; /* Spacing between sections */
}

.section-title {
    font-size: 2rem;
    font-weight: bold;
    color: #0046d5;
    margin-bottom: 1.5rem; /* Slightly increased bottom margin */
    text-align: center;
    text-transform: uppercase;
}

.values-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.8;
    color: #555;
}

.values-list li {
    margin-bottom: 1rem;
    padding-left: 1.5rem; /* Added padding for better alignment */
    position: relative;
}

.values-list li::before {
    content: "✔";
    color: #0046d5;
    font-size: 1.2rem;
    position: absolute;
    left: 0;
    top: 0;
}

/* Meet the Owner Section */
.about-us-meet-the-owner {
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08); /* Slight shadow */
    border-radius: 8px;
    text-align: center;
    padding: 2rem 3rem; /* Added padding inside the container */
    margin-bottom: 2rem; /* Spacing between sections */
}

.meet-the-owner-description {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #555;
}

.meet-the-owner-flyer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.meet-the-owner-image {
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1); /* Slightly stronger shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.meet-the-owner-image:hover {
    transform: scale(1.02); /* Subtle zoom effect on hover */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 0 1rem; /* Reduced padding for smaller screens */
    }

    .about-us-hero-content {
        padding: 6rem 1rem 3rem; /* Adjusted left and right padding for mobile */
    }

    .about-us-title {
        font-size: 2.5rem;
    }

    .about-us-mission {
        font-size: 1.2rem;
    }

    .section-title {
        font-size: 1.8rem;
    }

    .values-list {
        font-size: 1rem;
    }

    .about-us-values-section,
    .about-us-meet-the-owner {
        padding: 2rem 1.5rem; /* Reduced padding for smaller screens */
    }

    .meet-the-owner-description {
        font-size: 1rem;
    }

    .meet-the-owner-image {
        max-width: 300px;
    }
}
