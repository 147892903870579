/* General Page Styles */
.terms-page {
    font-family: "Inter", sans-serif;
    line-height: 1.8;
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Hero Section */
.terms-hero {
    background-color: #f4f4f4;
    text-align: center;
    padding: 4rem 2rem;
    border-bottom: 1px solid #ddd;
}

.terms-hero h1 {
    font-size: 3rem;
    color: #0056d2;
    margin: 0;
    font-weight: bold;
}

/* Content Section */
.terms-content {
    max-width: 800px;
    margin: 2rem auto;
    padding: 0 1.5rem;
}

.terms-content section {
    margin-bottom: 2rem;
}

.terms-content h2 {
    font-size: 1.8rem;
    color: #0056d2;
    margin-bottom: 1rem;
}

.terms-content p {
    font-size: 1rem;
    color: #444;
    margin: 0 0 1rem;
    text-align: justify;
}

/* Footer Styles */
footer {
    margin-top: auto;
    padding: 1.5rem;
    background-color: #f4f4f4;
    text-align: center;
    font-size: 0.9rem;
    color: #555;
    border-top: 1px solid #ddd;
}
