.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: #fff;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .disclaimer {
    color: #d9534f;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  
  .button {
    padding: 0.6rem 1.2rem;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.95rem;
    transition: all 0.3s ease;
    margin: 0.5rem;
  }
  
  .button-primary {
    background: linear-gradient(90deg, #4facfe, #0056d2);
    color: #fff;
    border: none;
  }
  
  .button-primary:hover {
    background: linear-gradient(90deg, #3a8dfe, #0041a8);
  }
  
  .selected {
    border: 2px solid #0056d2;
  }
  
  .approval-odds {
    margin-top: 1.5rem;
    padding: 1rem;
    background: #e6f0ff;
    border-radius: 8px;
    color: #0056d2;
  }
  