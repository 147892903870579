/* Page Background Styling */
body {
  background-image: url("../../assets/heroimage.svg"); /* Ensure correct path to your image */
  background-size: cover; /* Cover the entire viewport */
  background-repeat: no-repeat; /* Prevent tiling */
  background-attachment: fixed; /* Fixed background for parallax effect */
  background-position: center; /* Center the image */
  font-family: 'Roboto', sans-serif; /* Ensure consistent font */
  margin: 0; /* Reset margin for full background coverage */
  padding: 0; /* Reset padding */
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure body covers full viewport height */
}

/* Container Styling */
.quote-tool-container {
  max-width: 900px;
  margin: 150px auto; /* Adjusted margin for better spacing */
  padding: 40px 50px; /* Balanced padding for form content */
  background-color: rgba(255, 255, 255, 0.95); /* Slight transparency for overlay effect */
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  text-align: center;
}

/* Header Styling */
.quote-tool-container h2 {
  text-align: center;
  color: #0056d2;
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
  text-transform: uppercase; /* Adds a professional tone */
}

/* Form Styling */
.quote-tool-container .quote-form {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two-column layout for larger screens */
  gap: 30px; /* Added gap to ensure proper spacing between fields */
}

/* Form Group Styling */
.quote-tool-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.quote-tool-container .form-group label {
  font-size: 1rem;
  color: #333333;
  font-weight: bold;
  margin-bottom: 8px;
}

.quote-tool-container .form-group input,
.quote-tool-container .form-group select,
.quote-tool-container .form-group textarea {
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid #cccccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Ensures consistent width for all inputs */
  box-sizing: border-box; /* Avoid width overflow */
}

/* Focus Styles */
.quote-tool-container .form-group input:focus,
.quote-tool-container .form-group select:focus,
.quote-tool-container .form-group textarea:focus {
  outline: none;
  border-color: #0056d2;
  box-shadow: 0 0 6px rgba(0, 86, 210, 0.3); /* Blue focus glow */
  background-color: #ffffff;
}

/* Full-Width Inputs */
.quote-tool-container .form-group-full {
  grid-column: 1 / -1; /* Span full width of the form */
}

/* Checkbox and Radio Button Styling */
.quote-tool-container .form-group input[type="checkbox"],
.quote-tool-container .form-group input[type="radio"] {
  width: auto;
  margin-right: 8px;
}

.quote-tool-container .form-group div {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Submit Button */
.quote-tool-container .submit-btn {
  padding: 14px 24px;
  font-size: 1rem;
  color: #ffffff;
  background: linear-gradient(90deg, #4facfe, #0056d2);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  font-weight: bold;
  max-width: 250px;
  width: 100%;
  margin: 30px auto 0;
  grid-column: 1 / -1; /* Span the full form width */
}

.quote-tool-container .submit-btn:hover {
  background: linear-gradient(90deg, #3a8dfe, #0041a8);
  transform: scale(1.05);
}

/* Quote Confirmation Message */
.quote-tool-container .quote-confirmation {
  margin-top: 30px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  grid-column: 1 / -1; /* Span the full form width */
}

.quote-tool-container .quote-confirmation h3 {
  color: #0056d2;
  font-size: 1.8rem;
}

/* Footer Styling */
footer {
  margin-top: auto; /* Push footer to the bottom */
  padding: 20px 50px;
  background-color: #f4f4f4;
  text-align: center;
  font-size: 0.9rem;
  color: #555555;
  border-top: 1px solid #e0e0e0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .quote-tool-container .quote-form {
    grid-template-columns: 1fr; /* Single-column layout for small screens */
    gap: 20px; /* Adjust gap for single-column layout */
  }

  .quote-tool-container {
    padding: 30px;
  }
}
