/* General Styles */
.pricing-page {
    font-family: "Inter", sans-serif;
    line-height: 1.8;
    color: #333;
    margin: 0;
    padding: 0;
    position: relative;
    background-image: url("../../assets/heroimage.svg");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* Centralized Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

/* Hero Section */
.pricing-hero-background {
    text-align: center;
    padding: 6rem 2rem;
    flex-grow: 1;
}

.pricing-title {
    font-size: 3rem;
    font-weight: 700;
    color: #0038a8;
    margin-bottom: 1rem;
    letter-spacing: 0.5px;
    text-transform: capitalize;
}

.pricing-description {
    font-size: 1.4rem;
    font-weight: 400;
    color: #444;
    margin-bottom: 2rem;
}

/* Pricing Section */
.pricing-section {
    text-align: center;
    padding: 2rem 3rem;
    margin-bottom: 2rem;
}

.holiday-pricing-image {
    width: 100%;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: -2rem; /* Move image closer to header */
}

@media (max-width: 768px) {
    .holiday-pricing-image {
        width: 95%; /* Take up most of the screen with thin padding */
        max-width: none; /* Remove max-width to allow it to scale */
        margin: 0 auto; /* Center the image */
    }
}

.holiday-pricing-image:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Responsive Design */
@media (max-width: 768px) {
    .container {
        padding: 0 1rem;
    }

    .pricing-hero-background {
        padding: 4rem 1rem;
    }

    .pricing-title {
        font-size: 2.5rem;
    }

    .pricing-description {
        font-size: 1.2rem;
    }

    .holiday-pricing-image {
    width: 100%;
    max-width: 800px;
    border-radius: 8px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: -2rem; /* Move image closer to header */
}

@media (max-width: 768px) {
    .holiday-pricing-image {
        width: 95%; /* Take up most of the screen with thin padding */
        max-width: none; /* Remove max-width to allow it to scale */
        margin: 0 auto; /* Center the image */
    }
}
}
